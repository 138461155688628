
















































































/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */

import {
  Vue, Component, Watch,
} from 'vue-property-decorator';

@Component
export default class SignupDialog extends Vue {
  dialog = false

  loading = false
  valid = false

  alert = false
  error = ''

  firstName = ''
  lastName = ''
  email = ''

  emailRules = [
    (v: string) => !!v || "L'indirizzo email è richiesto",
    (v: string) => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\s*$/
      .test(v) || "L'indirizzo email non è un indirizzo corretto",
  ]

  get value(): boolean {
    return this.$store.getters.signupDialog;
  }

  get form(): Vue & {
    validate: () => boolean;
    reset: () => void;
    resetValidation: () => void;
    } {
    return this.$refs.form as Vue & {
      validate: () => boolean;
      reset: () => void;
      resetValidation: () => void;
    };
  }

  @Watch('value')
  onValueChanged(val: boolean) {
    const debugPrefix = 'SignupDialog: onValueChanged()';
    console.log(debugPrefix); // eslint-disable-line no-console

    if (val) {
      this.firstName = '';
      this.lastName = '';
      this.email = '';

      this.alert = false;
      this.error = '';

      if (this.form) {
        this.form.reset();
        // this.form.resetValidation();
      }
      this.loading = false;
    }

    this.dialog = val;
  }

  @Watch('dialog')
  onDialogChanged(val: boolean) {
    const debugPrefix = 'SignupDialog: onDialogChanged()';
    console.log(debugPrefix); // eslint-disable-line no-console

    if (this.value !== val) {
      this.$store.dispatch('toggleDialog', 'signup');
    }

    // Scrolla in cima alla finestra
    // Questo metodo funziona solo per le dialog scrollabili
    if (val) {
      this.$nextTick(() => {
        const element = document.querySelector('.v-dialog--active > .v-card > .v-card__text');
        if (element) this.$vuetify.goTo(0, { container: element as HTMLElement, duration: 0 });
      });
    }
  }

  async onSignup() {
    const debugPrefix = 'SignupDialog: onSignup()';
    console.log(debugPrefix); // eslint-disable-line no-console

    const formRef = this.$refs.form;

    if ((formRef as any).validate()) {
      this.loading = true;

      try {
        await this.$store.dispatch('signupUser', {
          firstName: this.firstName.trim(),
          lastName: this.lastName.trim(),
          email: this.email.trim(),
        });

        // La registrazione è (apparentemente) riuscita,
        // chiuda il dialog e comunica il successo.
        this.loading = false;
        this.dialog = false;
        this.$store.dispatch('openDialog', 'signupSuccess');
      } catch (error) {
        // Qualcosa è andato storto
        console.log(`${debugPrefix} - error =`, error); // eslint-disable-line no-console

        this.loading = false;

        switch (error) {
          case 'auth/invalid-email':
            this.error = "L'indirizzo email non è nel formato corretto";
            break;

          case 'auth/email-already-exists':
            this.error = "L'indirizzo email è utilizzato da un altro utente";
            break;

          default:
            this.error = `Errore sconosciuto (${error})`;
            break;
        }

        this.alert = true;
      }
    }
  }
}
